<template>


    <div class="p-d-flex p-flex-column container" >
        <div class="p-d-flex p-jc-center" style="margin-top:12em;">
            <div class="p-d-flex p-flex-column box" >
                <div class="p-mb-2">
                    <div class="p-mt-3">
                        <div class="box-text"> Portal de Consentimentos </div>
                    </div>
                </div>

                <div id="msg1" style="display:none">
                    <MessagesManager :msg="msgtext"/>
                </div>     
                
                <div id="msg2" style="display:none">
                    <MessagesManager :msg="msgtext2"/>
                </div>
                 
                <div class="p-mb-2" style="padding-top:32px; padding-bottom:34px; align-self: center;">
                    
                        <Button  type="button" class="b-class" v-bind:disabled="searchDisabled" @click="handleClick($event)" id="myBtn">
                            <span> Login </span>
                        </Button>
                    
                </div>
                
            
            </div>
        </div>
    </div>
  
</template>

<script>
export default {

    data(){
        return {
            searchDisabled: false,
            msgtext:'Houve falha na comunicação. Tente novamente mais tarde.',
            msgtext2:'Tempo excedido. Você atingiu o limite de 05 minutos sem executar nenhuma ação.',
            consentId:'',
            azureUrl:process.env.VUE_APP_AZURE
        }
    },
    methods:  {
        test(message){
            console.log(message);
        },
        handleClick(){
           window.location.href = this.azureUrl
        },
         checkOnline(){
            this.onLine = navigator.onLine ?true:false
            if(!navigator.onLine){
                var el = document.getElementById("msg1");
                el.style.display = "flex";
                this.searchDisabled = true;
 
            }
        },
        defTimeOut(){
            var timeout
            function refresh(){
            clearTimeout(timeout)
            timeout = setTimeout(() => {
            
            var el = document.getElementById("msg2");
            el.style.display = "flex";

            document.getElementById("myBtn").disabled = true; 
           
            
            },  300000)
            }
            refresh()
            document.addEventListener('click', refresh)

         },
          isEmpty(text){

            if (text === 'undefined' || text == null || !text) 
                    return true;

            return false;
        }
        
    },
    created() {
        
        setInterval(() => {
            this.checkOnline();
        }, 3000);
        this.$root.$refs.mainNavbar.showManagerNavbar();
        this.defTimeOut();
    

        


            const FETCH_TIMEOUT = 30000;
            let didTimeOut = false;
            var urlReq = process.env.VUE_APP_CONSENTS+"/consent/health";
            
            const promise = new Promise(function(resolve, reject){
                const timeout = setTimeout(function() {
                    didTimeOut = true;
                    reject(new Error('Request timed out'));
                }, FETCH_TIMEOUT);
                
                fetch(urlReq)
                .then(function(response) {
                    // Clear the timeout as cleanup
                    clearTimeout(timeout);
                    if(!didTimeOut) {
                    
                        resolve(response.text());
                        
                    }
                })
                .catch(function(err) {
                    
                    // Rejection already happened with setTimeout
                    if(didTimeOut) return;
                    // Reject with error
                    reject(err);
                    //console.log(err)
            });
            });

        promise.then((response)=> {
            
            const resp = JSON.parse(response)
        //console.log(resp)




        })
        .catch((err) =>{
            // Error: response error, request timeout or runtime error

            //this.showWarningModal();
            console.log(err)

        });





    },
    mounted(){
        if(Object.prototype.hasOwnProperty.call(this.$route.params,"err" )){
           var el = document.getElementById("msg1");
           el.style.display = "flex";
           this.searchDisabled = true;
           

        }
    }
    
}
</script>

<style scoped>
.container {
    width: 100%;
    height: 100vh;
    background-color: var( --tecban-back-color1);
}

.box {
    min-width:506px;
    min-height:150px;
    background: var(--tecban-default-back-color);
    box-shadow: var(--tecban-default-shadow);
    padding:1em;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}
.box :active {
    border: none;
}
.box-text 
{
    font-family: var(--tecban-font);
    color: var(--tecban-text-gray2);
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
}
.b-class{
    min-height: 55px;
    border-radius: 8px; 
    box-shadow: var(--tecban-default-shadow); 
    background-color: var(--tecban-default-action-color);
    border:none;
    min-width: 312px; 
    justify-content: center;
    font-family: var(--tecban-font) !important;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
/* identical to box height, or 120% */
    display: flex;
    align-items: center;
    text-align: center;
/* Branco/BC100 */
    color: #FFFFFF;
}
.b-class:hover {
    background-color: var(--tecban-default-hover-red-color);
}
</style>          